/* *************bottom header******************* */
.main-navbar {
  height: 40px;
  border-bottom: 1px solid #ccc;
}
.first-title {
  color: #000;
  font-size: 14px;
}
.first-titless {
  color: #000;
  font-size: 14px;
  padding-top: 12px;
}
.first-icon {
  color: #000;
}
.first-iconss {
  color: #000;
  padding-top: 12px;
}
.navbar-brand img {
  max-height: 40px;
}
.search-bar {
  flex-grow: 1;
}
.search-bar input {
  width: 100%;
}
.category-select {
  max-width: 200px;
}
.search-button {
  min-width: 100px;
}
.advanced-link {
  line-height: 38px;
}

.search-button {
  color: white;
  background-color: #3665f3;
}
.search-button:hover {
  color: white;
  background-color: #3665f3;
}

/* *************bottom header******************* */

/* Styles for the search bar */
.img-fluid {
  max-width: 100%;
  height: auto;
}

.input-group {
  display: flex;
  width: 100%;
}

.input-group .form-control {
  flex: 1;
}

.input-group-append .btn {
  background-color: #3665f3;
  border-color: #3665f3;
  color: #fff;
}

.logo {
  max-width: 150px;
}
.logosshide {
  display: none;
}

.shopcategorytitle {
  font-size: 15px;
  color: #000;
  cursor: pointer;
  align-items: center;
}
@media (max-width: 768px) {
  .input-group {
    flex-direction: row;
  }

  .input-group .form-control,
  .input-group-append .btn {
    width: 100%;
  }

  .input-group-append .btn {
    width: auto;
  }
  .search-drop {
    display: none;
  }
  .logo {
    display: none;
  }
  .logodiv {
    display: none;
  }
  .logosshide {
    display: block;
    max-width: 70px;
    margin-top: -16px;
  }

  .shopcategorytitle {
    font-size: 13px;
    color: #000;
  }
}

/* Styles for the main-navbar */
.main-navbar {
  background-color: #fff; /* Dark background color */
  padding: 15px 20px;
  z-index: 1000; /* Ensure navbar is on top */
  position: relative;
}

.main-navbar .navbar-nav .nav-link {
  color: #000; /* White text color */
}

.main-navbar .navbar-toggler {
  border: none;
  color: #000;
  padding: 0;
  margin-top: -5px;
  /* White color for the toggle icon */
}

/* Ensuring the icons are visible and styled properly */
.main-navbar .navbar-nav .nav-link .fas {
  color: #000; /* White color for icons */
}

/* Handling padding and other adjustments */
.main-navbar .navbar-nav .nav-link {
  padding-right: 15px; /* Add some padding to the right */
}
.notiname {
  display: none;
}
.cartname {
  display: none;
}
/* Responsive styles for mobile screens */
@media (max-width: 991px) {
  .main-navbar .navbar-nav .nav-item {
    left: 0;
    background-color: #fff;
    margin-left: 0;
    padding-left: 20px;
    padding-right: 20px;
  }
  .nav-icon-cart {
    padding-left: 9px !important;
  }
  .main-navbar .navbar-collapse {
    padding: 0 20px;
  }

  .main-navbar .navbar-nav {
    flex-direction: column;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;

    /* text-align: center; */
  }

  .main-navbar .navbar-nav .nav-link {
    padding: 10px 0;
    border-bottom: 1px solid #ccc;
  }
  .logo {
    display: none;
  }
  .logodiv {
    display: none;
  }
  .logosshide {
    display: block;
    max-width: 70px;
    margin-top: -16px;
  }
  .shopcategorytitle {
    font-size: 14px;
    color: #000;
    align-items: center;
  }
  .notiname {
    display: inline-block;
  }
  .cartname {
    display: inline-block;
  }
}

/* Styles for the main content to ensure it does not overlap */
#main-content {
  padding-top: 60px; /* Adjust based on the height of your navbar */
}

/* add media query for web */

@media (min-width: 768px) and (max-width: 991px) {
  .secondheader {
    margin: 0px 4rem;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .secondheader {
    margin: 0px 4rem;
  }
}
@media (min-width: 1200px) {
  .secondheader {
    margin: 0px 4rem;
  }
}
/* .secondheader {
  margin: 0px 4rem;
} */

.search-container {
  position: relative;
  width: 100%;
}

.search-dropdowns {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 1000;
  max-height: 400px;
  max-width: 100%;
  overflow-y: auto;
  background-color: #fff;
  overflow-x: hidden;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  /* border: 1px solid #ddd; */
}

.search-dropdowns .dropdown-item {
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.search-dropdowns .dropdown-item:last-child {
  border-bottom: none;
}

@media (max-width: 768px) {
  .search-container {
    padding: 10px;
  }

  .search-dropdowns {
    max-height: 200px;
  }
}

.searchname {
  font-size: 14px;
}
