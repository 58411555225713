.stepss .stepsss {
  display: block;
  width: 100%;
  margin-bottom: 35px;
  text-align: center;
}

.stepss .stepsss .step-icon-wrap {
  display: block;
  position: relative;
  width: 100%;
  height: 60px;
  text-align: center;
}

.stepss .stepsss .step-icon-wrap::before,
.stepss .stepsss .step-icon-wrap::after {
  display: block;
  position: absolute;
  top: 50%;
  width: 50%;
  height: 3px;
  margin-top: -1px;
  background-color: #e1e7ec;
  content: "";
  z-index: 1;
}

.stepss .stepsss .step-icon-wrap::before {
  left: 0;
}

.stepss .stepsss .step-icon-wrap::after {
  right: 0;
}

.stepss .stepsss .step-icon {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px;
  border: 1px solid #e1e7ec;
  border-radius: 50%;
  background-color: #f5f5f5;
  color: #374250;
  font-size: 38px;
  line-height: 51px;
  z-index: 5;
  cursor: pointer;
}

.stepss .stepsss .step-title {
  margin-bottom: 0;
  color: #606975;
  font-size: 14px;
  font-weight: 500;
}

.stepss .stepsss:first-child .step-icon-wrap::before {
  display: none;
  cursor: pointer;
}

.stepss .stepsss:last-child .step-icon-wrap::after {
  display: none;
  cursor: pointer;
}

.stepss .stepsss.completed .step-icon-wrap::before,
.stepss .stepsss.completed .step-icon-wrap::after {
  background-color: #078133;
}

.stepss .stepsss.completed .step-icon {
  border-color: #078133;
  background-color: #078133;
  color: #fff;
  cursor: pointer;
}
.stepsss .cancelled {
  border-color: red;
  background-color: red;
  color: #fff;
  cursor: pointer;
}
@media (max-width: 576px) {
  .flex-sm-nowrap .stepsss .step-icon-wrap::before,
  .flex-sm-nowrap .stepsss .step-icon-wrap::after {
    display: none;
  }
}

@media (max-width: 768px) {
  .flex-md-nowrap .stepsss .step-icon-wrap::before,
  .flex-md-nowrap .stepsss .step-icon-wrap::after {
    display: none;
  }
}

@media (max-width: 991px) {
  .flex-lg-nowrap .stepsss .step-icon-wrap::before,
  .flex-lg-nowrap .stepsss .step-icon-wrap::after {
    display: none;
  }
}

@media (max-width: 1200px) {
  .flex-xl-nowrap .stepsss .step-icon-wrap::before,
  .flex-xl-nowrap .stepsss .step-icon-wrap::after {
    display: none;
  }
}

.bg-faded,
.bg-secondary {
  background-color: #f5f5f5 !important;
}
