.buynowbtn {
  background-color: #3665f3;
  color: #fff;
  padding: 5px 10px;
  /* border-radius: 25px; */
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
}
.buynowbtn:hover {
  background-color: #3665f3;
  color: #fff;
}
/* .payment-checks {
  margin: 15px 0px;
  border-top: 1px solid #d4d1d1;
  padding-top: 12px;
} */
.paywithname {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}

.payment-checks {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px 10px 20px 35px;
  background-color: #f9f9f9;
  transition: background-color 0.3s, border-color 0.3s;
  align-items: center;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  /* margin-left: 10px; */
}

.payment-checks:hover {
  background-color: #e9e9e9;
  border-color: #ccc;
}
.address-detailss {
  margin-top: 35px;
}

.card-titlesss {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}

.applybtn {
  background-color: #3665f3;
  color: #fff;
  padding: 5px 10px;
  /* border-radius: 25px; */
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  width: 25%;
}

.applybtn:hover {
  background-color: #3665f3;
  color: #fff;
}

.addchanges {
  cursor: pointer;
  font-size: 14px;
  margin: 0px 0px 0px 19px;
  color: #3665f3;
}
.addaddress {
  cursor: pointer;
  border-top: 1px solid #d4d1d1;
  margin-top: 15px;
  font-size: 14px;
  color: #3665f3;
}
.addsavebtn {
  background-color: #3665f3;
  color: #fff;
  padding: 5px 10px;
  border-radius: 25px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
}
.addcancelbtn {
  color: #3665f3;
  padding: 5px 10px;
  border-radius: 25px;
  border: 1px solid #3665f3;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  width: 100%;
}
.addcancelbtn:hover {
  background-color: #f1f1f1;
  color: #3665f3;
}
.addsavebtn:hover {
  background-color: #3665f3;
  color: #fff;
}
