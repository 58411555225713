.shopping-cart {
  padding-bottom: 50px;
  font-family: "Montserrat", sans-serif;
}

.shopping-cart.dark {
  background-color: #fff;
}

.shopping-cart .content {
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.075);
  background-color: white;
  padding: 10px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.shopping-cart .block-heading {
  padding-top: 20px;
  margin-bottom: 20px;
  text-align: center;
}

.shopping-cart .block-heading p {
  text-align: center;
  max-width: 420px;
  margin: auto;
  opacity: 0.7;
}

.shopping-cart .dark .block-heading p {
  opacity: 0.8;
}

.shopping-cart .block-heading h1,
.shopping-cart .block-heading h3 {
  margin-bottom: 1rem;
  color: #000;
}
.block-heading .shoptitle {
  color: #000;
  font-weight: 700;
  text-align: left;
  font-size: 25px;
}

.shopping-cart .items {
  margin: auto;
}

.shopping-cart .items .product {
  /* margin-bottom: 20px; */
  padding-top: 20px;
  /* padding-bottom: 20px; */
}

.shopping-cart .items .product .info {
  padding-top: 0px;
  text-align: center;
}

.shopping-cart .items .product .info .product-name {
  font-weight: 600;
}

.product-name .pro-name {
  color: #000;
}
.checkout-btn {
  background-color: #3665f3;
  color: #fff;
  font-size: 18px;
  /* border-radius: 25px; */
}

.checkout-btn:hover {
  color: #fff;
}

.shopping-cart .items .product .info .product-name .product-info {
  font-size: 14px;
  margin-top: 15px;
}

.shopping-cart .items .product .info .product-name .product-info .value {
  font-weight: 400;
}

.shopping-cart .items .product .info .quantity .quantity-input {
  margin: auto;
  width: 80px;
}

.shopping-cart .items .product .info .price {
  margin-top: 15px;
  font-weight: bold;
  font-size: 22px;
}

.shopping-cart .summary {
  /* border-top: 2px solid #5ea4f3; */
  background-color: #f7fbff;
  height: 100%;
  padding: 30px;
}

.shopping-cart .summary h3 {
  text-align: center;
  font-size: 1.3em;
  font-weight: 600;
  padding-top: 20px;
  padding-bottom: 20px;
}

.shopping-cart .summary .summary-item:not(:last-of-type) {
  padding-bottom: 10px;
  padding-top: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.shopping-cart .summary .text {
  font-size: 1em;
  font-weight: 600;
}

.shopping-cart .summary .price {
  font-size: 1em;
  float: right;
}

.shopping-cart .summary button {
  margin-top: 20px;
}

@media (min-width: 768px) {
  .shopping-cart .items .product .info {
    padding-top: 25px;
    text-align: left;
  }

  .shopping-cart .items .product .info .price {
    font-weight: bold;
    font-size: 20px;
    top: 17px;
  }

  .shopping-cart .items .product .info .quantity {
    text-align: center;
  }
  .shopping-cart .items .product .info .quantity .quantity-input {
    padding: 4px 10px;
    text-align: center;
  }
}
