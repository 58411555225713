.tab-group {
  list-style: none;
  padding: 0;
  margin: 0 0 40px 0;

  &:after {
    content: "";
    display: table;
    clear: both;
  }
  .tab a {
    display: block;
    text-decoration: none;
    padding: 8px;
    background: #fff;
    color: #000;
    font-size: 15px;
    float: left;
    width: 50%;
    text-align: center;
    cursor: pointer;
    transition: 0.5s ease;
    border-radius: 25px;
  }
  .active a {
    background: #000;
    color: white !important;
  }
}
.backregister-btn {
  cursor: pointer;
  color: #3374f4;
}
