.product-titlee {
  font-size: 22px;
  margin-bottom: 10px;
  font-weight: 600;
}

.seller-infoe {
  margin-bottom: 10px;
}

.price-valuee {
  font-size: 24px;
  font-weight: bold;
}

.price-offere {
  font-size: 16px;
  color: #555;
}

.conditione {
  margin-bottom: 10px;
}

.quantitye {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.buttonse .btn {
  font-size: 16px;
  padding: 12px;
}

.watchinge {
  margin-top: 20px;
  font-size: 14px;
  color: #555;
}

.buyitnow-btn {
  background-color: #3665f3;
  color: #fff;
  border-radius: 25px;
}
.buyitnow-btn:hover {
  color: #fff;
}
.addcarditnow-btn {
  border-radius: 25px;
  background-color: #fff;
  color: #3665f3;
  border: 1px solid #3665f3;
}
.addcarditnow-btn:hover {
  color: #3665f3;
  background-color: #ece8e8;
}
.additnow-btn {
  border-radius: 25px;
  background-color: #fff;
  color: #3665f3;
  border: 1px solid #3665f3;
}
.additnow-btn:hover {
  color: #3665f3;
  background-color: #ece8e8;
}
.main-image-container {
  width: auto;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.carousel-item img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  filter: invert(100%);
}

.scrollable-containeras {
  display: flex;
  overflow-x: auto;
  width: 100%;
  max-width: 300px;
}
.scrollable-containeras img {
  flex: 0 0 auto;
}
.scrollable-containeras::-webkit-scrollbar {
  height: 6px;
}
.scrollable-containeras::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.scrollable-containeras::-webkit-scrollbar-thumb {
  background: #888;
}
.scrollable-containeras::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.shareicon {
  font-size: 14px;
  cursor: pointer;
  color: #636161;
  display: flex;
}
.shareicon:hover {
  color: #3665f3;
  font-weight: 600;
}

/* *******************sharepopup******************** */

.popup .content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.popup .icons {
  display: flex;
  gap: 60px;
  margin: 10px 0;
}

.popup .icons .fbstyle {
  color: #fff;
  font-size: 20px;
  transition: color 0.3s;
  padding: 10px;
  background-color: #0056b3;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.popup .icons .fbstyle:hover {
  background-color: #0056b3;
}
.popup .icons .twitterstyle {
  color: #fff;
  font-size: 20px;
  transition: color 0.3s;
  padding: 10px;
  background-color: #000;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.popup .icons .twitterstyle:hover {
  background-color: #000;
}
.popup .icons .linkedinstyle {
  color: #fff;
  font-size: 20px;
  transition: color 0.3s;
  padding: 10px;
  background-color: #0077b5;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}

.popup .icons .linkedinstyle:hover {
  background-color: #0077b5;
}

.popup .field {
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: 10px;
}

.popup .field .url-icon {
  margin-right: 10px;
  color: #333;
}

.popup .field input {
  flex: 1;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 4px;
  outline: none;
}

.popup .field .copy-btns {
  margin-left: 10px;
  padding: 5px 10px;
  background: #3665f3;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background 0.3s;
}

.popup .field .copy-btns:hover {
  background: #0056b3;
}

.custom-modal .modal-content {
  border-radius: 20px;
  border: none;
}

.dd {
  color: #1e1e1e;
}
.ratingtext {
  /* color: #636161; */
  font-size: 14px;
  margin-left: 10px;
  margin-top: 3px;
  font-weight: 600;
}
.ratingtextsss {
  font-size: 20px;
  margin-left: 10px;
  margin-top: 3px;
}
.userrating {
  background-color: green;
  color: white;
  font-size: 14px;
  margin-left: 10px;
  margin-top: 3px;
  font-weight: 600;
  border-radius: 5px;
  padding: 5px;
}
.ratingfeed {
  font-size: 14px;
  margin-left: 10px;
}
.ratingfeedname {
  font-size: 15px;
  margin-left: 10px;
  color: #636161;
  font-weight: 600;
  margin-bottom: 0px;
}
.viewallre {
  color: #3665f3;
  font-size: 14px;
  margin-left: 10px;
  margin-top: 3px;
  font-weight: 600;
  cursor: pointer;
}

.offersexpire {
  color: #9b9a9a;
  font-size: 25px;
  margin-left: 10px;
  margin-top: 3px;
  font-weight: 600;
}

.titledescrip {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.prodesc {
  font-weight: 600;
  font-size: 14px;
  color: #525353;
}
