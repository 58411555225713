.fill-btn {
  width: 231px;
  border-radius: 50px;
  background-color: #3665f3;
}
.no-fill-btn {
  width: 231px;
  border-radius: 50px;
  background-color: white;
  color: #3665f3;
}
.no-fill-btn:hover {
  color: #fff;
}
.watch {
  background-color: #e8e8e8;
}
.helo {
  color: #000;
  font-weight: 500;
  text-align: left;
  font-size: 25px;
}
.watch-search {
  border-radius: 50px;
  background-color: #3665f3;
}
.watch-title {
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}
.watch-delete {
  color: #3665f3;
  background-color: #fff;
  border: 1px solid #3665f3;
  border-radius: 50px;
  font-size: 15px;
  width: 100px;
  margin-left: 20px;
}
.watch-delete:hover {
  color: #fff;
  background-color: #3665f3;
}

input[type="checkbox"] {
  /* width: 3em;
  height: 3rem; */
  accent-color: #3665f3;
}

.card-img-containerss {
  /* background-color: #ccc; */
}

.card-img-containerss img {
  width: 90%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container while maintaining aspect ratio */
}
