.notification-card {
  background: #f9f9f9;
  border: none;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.notification-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
}

.notification-card .card-title {
  font-weight: bold;
  font-size: 1.1rem;
  margin-left: 5px;
}

.notification-card .card-text {
  margin-bottom: 20px;
}

.notification-card .btn-outline-primary {
  border-radius: 50px;
  padding: 0.5rem 1.5rem;
}
