.text-successsss {
  font-family: "Times New Roman", Times, serif;
}
.partext {
  font-family: "Times New Roman", Times, serif;
  font-size: 22px;
}
.paronetext {
  font-family: "Times New Roman", Times, serif;
  font-size: 20px;
}
.textemail {
  cursor: pointer;
}
