section {
  margin-bottom: 20px;
}

.photos-video .upload-section {
  display: flex;
  gap: 10px;
}

.upload-box {
  flex: 1;
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
}
.upload-box {
  flex: 1;
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s;
}

.upload-box:hover {
  background-color: #f0f0f0;
}

.specifics-box {
  display: flex;
  align-items: center;
  gap: 10px;
}

.specifics-box label {
  flex: 1;
}

.specifics-box input {
  flex: 2;
}

.section-header {
  font-weight: 700;
}

.listing-section {
  margin-bottom: 20px;
  border-bottom: 2px solid #e9e3e3;
}

.btn-customs {
  margin: 10px 0;
  width: 100%;
  background-color: #3665f3;
  color: #fff;
  border-radius: 25px;
  font-weight: 500;
}
.btn-customss {
  margin: 10px 0;
  width: 100%;
  border: 2px solid #808080;
  border-radius: 25px;
  font-weight: 500;
}
.btn-customs:hover {
  background-color: #3665f3;
  color: #fff;
}

.container-custom {
  max-width: 400px;
  margin: 50px auto;
  text-align: center;
}
.addcustom-item {
  background-color: #e4e3e3;
  padding: 5px 10px;
  border-radius: 25px;
  font-weight: 600;
  cursor: pointer;
}
.item-condition-name {
  font-weight: 500;
  font-size: 18px;
  cursor: pointer;
  color: #000;
}
.btn-savss {
  background-color: #3665f3;
  color: #fff;
  border-radius: 25px;
  font-weight: 500;
  margin: 20px 0;
  width: 100%;
}
.btn-savss:hover {
  background-color: #3665f3;
  color: #fff;
}
.btn-closess {
  border: 2px solid #808080;
  border-radius: 25px;
  font-weight: 500;
  margin: 20px 0;
  width: 100%;
}

/* Responsive Design */
@media (max-width: 768px) {
  .photos-video .upload-section {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  header h1 {
    font-size: 18px;
  }
}
.stoketitle {
  color: #368bf3;
  cursor: pointer;
}

.image-preview-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 images per row */
  grid-gap: 10px; /* Spacing between images */
  margin-top: 10px;
}

.image-preview {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  overflow: hidden;
}

.imgpack {
  max-width: 100%;
  height: auto;
  object-fit: cover; /* Ensures the image covers the box without distortion */
}
